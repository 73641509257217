import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(ChainedBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      backendOptions: [{ expirationTime: 1 * 60 * 1000 }, {}],
      backends:
        typeof window !== "undefined" ? [LocalStorageBackend, HttpBackend] : [],
    },
    debug: false,
    defaultLocale: "en",
    locales: ["en", "ru", "uk", 'de', 'es', 'fr', 'it', 'tr'],
    localePath: "./public/locales",
    localeDetection: true,
    defaultNS: "translation",
    detection: {
      order: ["localStorage", "navigator"],
    },
  } as any);

export default i18n;
